import React from "react"
import { Link, graphql } from "gatsby"
import Granim from "react-granim"

import Layout from "../components/layout"
import Image from "../components/image"
import Contact from "../components/form-netlify"
import SEO from "../components/seo"
import trees from "../images/trees.jpg"

// ["#365E8C", "#485563"],
//         ["#FF6B6B", "#556270"],
//         ["#80d3fe", "#7ea0c4"],
//         ["#f0ab51", "#eceba3"],
const settings = {
  element: "#canvas-image-blending",
  direction: "top-bottom",
  isPausedWhenNotInView: true,
  image: {
    source: trees,
    blendingMode: "multiply",
    position: ["center", "center"],
    stretchMode: ["stretch-if-smaller", "stretch-if-smaller"],
  },
  states: {
    "default-state": {
      gradients: [
        ["#365e8c", "#485563"],
        ["#2f6b58", "#60638a"],
        ["#80d3fe", "#7ea0c4"],
        ["#7fa45a", "#b6c180"],
      ],
      transitionSpeed: 7000,
    },
  },
}

const IndexPage = props => (
  <>
    <Granim {...settings} />
    <canvas id="canvas-image-blending"></canvas>
    <Layout>
      <SEO title="Home" />
      <div className={`mainwrap flex flex-wrap h-full z-10 relative`}>
        {/* <!-- pageheader --> */}
        <section className={`container px-5 mx-auto mb-5 sm:mb-20`}>
          <div
            className={`flex flex-wrap sm:-mx-2 flex justify-center items-center text-white`}
          >
            {/* <!-- intro-content --> */}
            <div
              className={`w-full lg:w-1/2 px-10 lg:pl-10 lg:pr-0 sm:mx-16 lg:mx-0`}
            >
              <h1
                className={`hidden mb-2 font-hairline xborder-b-2 md:border-b-0 lg:border-b-2 text-3xl md:text-4xl lg:text-5xl sm:block sm:text-center sm:leading-none sm:pb-3 xl:leading-tight lg:text-left`}
              >
                Confidential Counselling Services in the heart of Bristol
              </h1>
              <div className={`flex justify-between lg:text-lg hidden lg:flex`}>
                <span>
                  <a
                    className={`tracking-wide hover:text-gray-700`}
                    href="mailto:andrew@bristolcounsellingservices.co.uk"
                  >
                    Andrew Newton
                  </a>{" "}
                  <a
                    className={`font-bold hover:text-gray-700`}
                    href="https://www.bacp.co.uk/profile/4085aeb6-01dc-e711-80f1-3863bb349ac0/Therapist"
                  >
                    MBACP
                  </a>
                </span>
                <a
                  className={`flex items-center hover:text-gray-700`}
                  href="tel:+7834991034"
                >
                  <svg
                    className={`h-5 fill-current pr-2`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17 11v3l-3-3H8a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-1zm-3 2v2a2 2 0 0 1-2 2H6l-3 3v-3H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h2v3a4 4 0 0 0 4 4h6z" />
                  </svg>
                  0783 499 1034
                </a>
                <a
                  className={``}
                  href="mailto:andrew@bristolcounsellingservices.co.uk"
                  aria-label="Email"
                >
                  <svg
                    className={`h-5 fill-current hover:text-gray-700`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className={`w-full sm:mx-10 md:mx-0 md:mt-5 lg:w-1/2 sm:px-10 md:mx-0 lg:pl-0 xl:pl-20 lg:pr-0 text-center relative`}
            >
              <p
                className={`mb-4 md:mb-10 text-sm lg:text-lg sm:border px-2 sm:p-5 sm:mx-10 text-left xl:m-5 sm:bg-white-10`}
              >
                I offer confidential counselling at the{" "}
                <a
                  href="/#gmap_canvas"
                  className={"hover:text-gray-400 sm:mr-2"}
                >
                  Practice Rooms in Queens Square Bristol.
                </a>{" "}
                If you would like to book an an introductory session, please
                give me a call on{" "}
                <a
                  className={`inline-flex items-center hover:text-gray-700 font-bold`}
                  href="tel:+7834991034"
                >
                  0783 499 1034
                </a>{" "}
                or use the{" "}
                <a href="/#contact" className={"hover:text-gray-400"}>
                  contact form below.
                </a>
              </p>
              <a href="https://www.bacp.co.uk/profile/4085aeb6-01dc-e711-80f1-3863bb349ac0/Therapist" aria-label="BACP profile">
                <Image
              className="w-24 h-24 mx-auto mb-5 rounded-full sm:mr-5 sm:absolute sm:right-0 sm:-mt-16"
              filename="bacp-roundel.png"
            />
              </a>
              {/* <!-- button --> */}
              <a
                href="/#contact"
                className={`font-bold text-sm lg:text-base px-4 py-2 leading-none border rounded text-gray-800 bg-white border-white hover:border-transparent hover:text-white hover:bg-gray-800 mx-auto lg:hidden inline-block`}
              >
                Book a session
              </a>
            </div>
          </div>
        </section>

        {/* <!-- body --> */}
        <section
          className={`container px-5 sm:mt-10 mb-20 mx-auto text-center text-white`}
        >
          <div className={`flex flex-wrap -mx-2`}>
            <div className={`w-full px-2 mt-10 mb-5 font-bold`}>
              I have experience of dealing with a variety of issues including:
            </div>
            <div className={`w-full sm:w-1/3  px-2 `}>
              <ul>
                <li>Abuse</li>
                <li>Addiction</li>
                <li>Anger</li>
                <li>Anxiety</li>
                <li>Assertiveness</li>
                <li>Bereavement</li>
              </ul>
            </div>
            <div className={`w-full sm:w-1/3 px-2 `}>
              <ul>
                <li>Depression</li>
                <li>Guilt</li>
                <li>Life Skills</li>
                <li>Personal Development</li>
                <li>Phobias</li>
                <li>Relationships</li>
              </ul>
            </div>
            <div className={`w-full sm:w-1/3 px-2 `}>
              <ul>
                <li>Self Awareness</li>
                <li>Self Esteem</li>
                <li>Sexuality</li>
                <li>Shame</li>
                <li>Stress</li>
                <li>Work issues</li>
              </ul>
            </div>
          </div>
        </section>

        {/* <!--     Approach --> */}
        <section
          id="approach"
          className={`approach flex w-full bg-white text-gray-800`}
        >
          <div
            className={`container flex flex-wrap mx-auto items-centerx py-6 leading-normal`}
          >
            <div className={`w-full px-5 sm:px-10`}>
              <p
                className={`pb-2 text-base font-bold sm:text-xl sm:font-normal md:text-center border-b mb-5`}
              >
                Seeking counselling can be daunting. You may be feeling stuck,
                disconnected, suffering or in crisis.
              </p>
            </div>
            <div className={`w-full sm:w-1/2 px-5 sm:pl-10 sm:pr-5 md:pr-2 `}>
              <p>
                In my practice I try to cultivate a safe environment that is
                open, curious and welcoming to help you share your experience
                while developing a mindful awareness and deeper understanding of
                the way in which thoughts, feelings and actions impact your
                relationship with yourself, the world and those around you.
              </p>

              <p>
                There is an ongoing invitation to explore at depth what stops
                you from being who you really want to be, to rediscover and
                reconnect with what’s alive in you and allow you the possibility
                of changing unhelpful patterns.
              </p>

              <p>
                Having worked in the creative industries for much of my career
                I’m particularly interested in how we connect with our
                creativity and the challenges and difficulties that arise when
                seeking to express creativity in our life and work.
              </p>
            </div>

            <div className={`w-full sm:w-1/2 px-5 sm:pr-10 sm:pl-5 md:pl-2`}>
              <p>
                I believe strongly that the therapeutic process should be both
                creative and collaborative, naturally developing according to
                what is important and of value to you the client, while
                respecting your individuality and uniqueness as a person.
              </p>

              <p>
                Although you will not be given advice or told what to do, I will
                try and help you find answers within yourself.
              </p>

              <p>
                I'm a BACP registered, Integrative Counsellor which means that
                I'm trained in a range of psychological approaches. Although my
                work is largely Person Centred I can adapt to your needs and
                draw from other therapeutic approaches such as Gestalt and
                Psychodynamic theory whenever they can be helpful to the work.
              </p>
            </div>

            <div className={`w-full px-5 sm:px-10 pt-5`}>
              <p
                className={`pt-2 text-base font-bold sm:text-xl sm:font-normal md:text-center border-t mb-5`}
              >
                Although counselling can feel challenging at times, when
                compassion and creativity are at the heart of the work, it is a
                rich relational process that can lead to spontaneous moments of
                awareness, insight, joy, humour, clarity and freedom.
              </p>
            </div>
          </div>
        </section>
        <section
          id="about"
          className={`about flex w-full bg-blue-900 text-white leading-normal`}
        >
          <div
            className={`container flex flex-wrap mx-auto items-center py-20`}
          >
            <div
              className={`w-full sm:w-1/2 px-5 sm:pl-10 sm:pr-5 order-1 sm:order-none xl:-mx-10x`}
            >
              <h2 className={`font-bold`}>Andrew Newton - <a
                    className={`font-bold hover:text-gray-700`}
                    href="https://www.bacp.co.uk/profile/4085aeb6-01dc-e711-80f1-3863bb349ac0/Therapist"
                  >
                    MBACP
                  </a></h2>
              <p>
                I’m a BACP registered counsellor based in Bristol in the South
                West of England and see clients at the{" "}
                <a
                  className={`font-bold hover:text-gray-500" href="/#gmap_canvas`}
                >
                  Practice Rooms at 55 Queen Square
                </a>
                . I use a variety of theoretical approaches to assist you in
                understanding and working through your issues. The relationship
                with you, the client is central to my work.
              </p>
            </div>
            <div
              className={`w-full sm:w-1/2 px-10 sm:pr-10 sm:pl-5 text-center`}
            >
              <div className={`box-container`}>
                <div className={`box`}>
                  <div className={`spin-container`}>
                    <div className={`shape`}>
                      <div className={`bd`}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`flex w-full h-40 md:h-64 xl:h-auto`}>
          <Image className="w-1/3" filename="55qsfront.jpg" />
          <Image className="w-2/3" filename="yellow-room-sm.jpg" />
        </section>

        <section className={`flex w-full bg-gray-800 text-white`}>
          <div className={`container flex flex-wrap mx-auto items-center py-4`}>
            <div className={`w-full text-center px-10`}>
              <p
                className={`mb-2 md:mb-2 text-sm lg:text-lg xp-5 text-left md:text-center xl:m-5`}
              >
                Please feel free to speak to me by telephone on{" "}
                <a
                  className={`inline-flex items-center hover:text-gray-800 font-bold`}
                  href="tel:+7834991034"
                >
                  0783 499 1034
                </a>{" "}
                or use the form below to to set up an initial appointment.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- Contact Form --> */}
        <section id="contact" className={`flex w-full bg-gray-500 text-white contact`}>
          <div className={`container flex flex-wrap mx-auto items-center py-6`}>
            <div className={`w-full sm:w-1/2 px-10 sm:pr-10 sm:pl-15 `}>
              <Contact />
            </div>
            <div
              className={`w-full sm:w-1/2 px-10 sm:pl-10 sm:pr-5 md:pr-2 text-center`}
            >
              <div
                className={`border rounded shadow-lg inline-block p-5 mx-auto text-left bg-gray-700`}
              >
                <p className={`mb-0`}>
                  <a
                    className={`font-bold hover:text-gray-500" href="mailto:andrew@bristolcounsellingservices.co.uk`}
                  >
                    <svg
                      className={`h-5 fill-current hover:text-gray-500 inline-block mr-2`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"></path>
                    </svg>
                    Andrew Newton
                  </a>
                </p>
                <p className={`pl-6 ml-1 mb-0`}>The Practice Rooms,</p>
                <p className={`pl-6 ml-1 mb-0`}>55 Queens Square,</p>
                <p className={`pl-6 ml-1 mb-0`}>Bristol, BS1 4LH</p>
                <a
                  className={`inline-flex items-center hover:text-gray-500 font-bold pl-6 ml-1`}
                  href="tel:+7834991034"
                >
                  0783 499 1034
                </a>
              </div>
              <form
                action="https://maps.google.com/maps"
                method="get"
                target="_blank"
                className={`pt-20 text-center`}
              >
                <label
                  for="saddr"
                  className={`w-full block text-base text-gray-700 leading-snug mb-5 text-center`}
                >
                  For directions please enter your postcode.
                </label>
                <input
                  type="text"
                  name="saddr"
                  className={`dir-input block mb-2 mx-auto text-center`}
                  size="16"
                  placeholder="Postcode"
                  aria-label="Postcode"
                ></input>
                <input
                  type="hidden"
                  name="daddr"
                  value="55 Queen Square, Bristol, BS1 4LH, UK"
                ></input>
                <input
                  type="submit"
                  value="Get directions"
                  className={`text-sm lg:text-base px-4 py-2 leading-none rounded text-white bg-gray-800 hover:text-gray-800 hover:bg-white mx-auto cursor-pointer`}
                ></input>
              </form>
            </div>
          </div>
        </section>
        <iframe
          width="100%"
          height="490"
          title="Queens Square"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=55%20queens%20square%20bristol&t=&z=13&ie=UTF8&iwloc=&output=embed&z=16"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </Layout>
  </>
)

export default IndexPage
